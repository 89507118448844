<template>
  <div>
    <div class="card bg-light-primary mt-3">
      <div class="card-header bg-light-primary">
        {{ inspection.section_start.name }}
      </div>
      <div class="card-body">
        <Question
          v-for="question in inspection.section_start.questions"
          :key="question.order"
          :parent="inspection.section_start"
          :question="question"
          :bg="0"
        ></Question>
      </div>
    </div>

    <div
      class="card bg-light-warning mt-3"
      v-for="section in inspection.sections"
      :key="section.order"
    >
      <div class="card-header bg-light-warning">
        {{ section.name }}
      </div>
      <div class="card-body">
        <Question
          v-for="question in section.questions"
          :key="question.order"
          :parent="section"
          :question="question"
          :bg="0"
        ></Question>
      </div>
    </div>

    <!-- <div class="card bg-light-danger mt-3" v-if="getSafe === 'unsafe'">
      <div class="card-header bg-light-danger">
        {{ inspection.section_finish.name }}
      </div>
      <div class="card-body">
        <Question
          v-for="question in inspection.section_finish.questions"
          :key="question.order"
          :parent="inspection.section_start"
          :question="question"
          :bg="0"
        ></Question>
      </div>
    </div> -->
  </div>
</template>

<script>
import Question from "./Question";

export default {
  name: "Inspection",

  components: {
    Question
  },

  props: {
    inspection: Object,
    readonly: Boolean,
    safe: String
  },

  data: () => ({}),

  computed: {
    getSafe: {
      get: function() {
        return this.safe;
      }
    }
  },

  created() {},

  mounted() {},

  methods: {}
};
</script>
