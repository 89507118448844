<template>
  <div class="container-fluid px-0 mb-5">
    <div class="form-group row">
      <label class="col-form-label col-2">Вопрос</label>
      <div class="col-5">
        <select
          class="form-control"
          v-model="question.type"
          @change="updateAnswers(question)"
        >
          <option value="0" disabled>Выберите</option>
          <option
            v-for="option in filtered_question_types"
            :key="option.id"
            :value="option.id"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
      <div class="col-3 d-flex align-items-center">
        <b-form-group>
          <b-form-checkbox v-model="question.required" switch stacked
            >Нужный</b-form-checkbox
          >
          <b-form-checkbox v-model="isFile" switch stacked @change="switchFile"
            >Файл</b-form-checkbox
          >
        </b-form-group>
      </div>
      <div class="col-2">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown"
          no-caret
          right
          no-flip
          text="Действия"
          v-b-tooltip.hover="'Действия'"
        >
          <template v-slot:button-content>
            <a href="#" class="btn btn-icon" data-toggle="dropdown">
              <span class="svg-icon svg-icon-success svg-icon-2x">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Plus.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </template>
          <div class="navi navi-hover min-w-md-250px">
            <b-dropdown-text tag="div" class="navi-header font-weight-bold">
              <i
                class="flaticon2-information"
                data-toggle="tooltip"
                data-placement="left"
                v-b-tooltip.hover
                title="Дублирование позволяет перенести существующую структуру вопроса в новый вопрос. Удаление убирает вопрос со всеми ответами и их подвопросами из структуры теста"
              />
              Для этого вопроса доступны действия:
            </b-dropdown-text>
            <b-dropdown-text
              tag="div"
              class="navi-separator mb-3"
            ></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a
                href="#"
                class="navi-link"
                @click.prevent="
                  copyPasteQuestion(question, parent.questions.length + 1)
                "
              >
                <span class="navi-icon">
                  <i class="flaticon-add-circular-button"></i>
                </span>
                <span class="navi-text">Дублировать</span>
              </a>
            </b-dropdown-text>
            <b-dropdown-text
              tag="div"
              class="navi-item"
              v-if="
                !parent.hasOwnProperty('name') ||
                  (parent.hasOwnProperty('name') && parent.questions.length > 1)
              "
            >
              <a
                href="#"
                class="navi-link"
                @click.prevent="deleteQuestion(question)"
              >
                <span class="navi-icon">
                  <i class="flaticon-delete-1"></i>
                </span>
                <span class="navi-text">Удалить</span>
              </a>
            </b-dropdown-text>
          </div>
        </b-dropdown>
      </div>
    </div>
    <!-- type = 1 / 10-->
    <div
      class="container-fluid px-0"
      v-if="question.type === 1 || question.type === 10"
    >
      <div class="form-group row">
        <label class="col-form-label col-3">Текст вопроса</label>
        <div class="col-9">
          <input
            type="text"
            class="form-control"
            v-model="question.text"
            placeholder="Текст вопроса"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row mb-3"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>

      <div
        class="form-group row"
        v-for="answer in question.answers"
        :key="answer.order"
      >
        <label class="col-form-label col-2">Текст ответа</label>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            v-model="answer.text"
            :readonly="answer.readonly"
          />
        </div>
        <div class="col-2" v-if="answer.subs || answer.deletable">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="custom-v-dropdown"
            no-caret
            right
            no-flip
            text="Действия"
            v-b-tooltip.hover="'Действия'"
          >
            <template v-slot:button-content>
              <a href="#" class="btn btn-icon" data-toggle="dropdown">
                <span class="svg-icon svg-icon-success svg-icon-2x">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Code/Plus.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </template>
            <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                <i
                  class="flaticon2-information"
                  data-toggle="tooltip"
                  data-placement="left"
                  v-b-tooltip.hover
                  title="Добавление вложенного вопроса позволяет добавить следующий уровень вопросов, например, при определенном ответе, вы можете задать уточняющие вопросы. Удаление убирает вопрос со всеми подвопросами из структуры теста"
                />
                Для этого ответа доступны действия:
              </b-dropdown-text>
              <b-dropdown-text
                tag="div"
                class="navi-separator mb-3"
              ></b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="answer.subs">
                <a
                  href="#"
                  class="navi-link"
                  @click.prevent="
                    addQuestion(
                      answer,
                      answer.questions.length ? answer.questions.length + 1 : 1
                    )
                  "
                >
                  <span class="navi-icon">
                    <i class="flaticon-add-circular-button"></i>
                  </span>
                  <span class="navi-text">Добавить уточняющий вопрос</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-text
                tag="div"
                class="navi-item"
                v-if="answer.deletable && question.answers.length > 1"
              >
                <a
                  href="#"
                  class="navi-link"
                  @click.prevent="deleteAnswer(answer)"
                >
                  <span class="navi-icon">
                    <i class="flaticon-delete-1"></i>
                  </span>
                  <span class="navi-text">Удалить</span>
                </a>
              </b-dropdown-text>
            </div>
          </b-dropdown>
        </div>

        <div class="container-fluid px-0" v-if="answer.questions.length">
          <div class="card mt-3 pt-3" :class="bgs[current_bg]">
            <div class="card-body">
              <Question
                v-for="question in answer.questions"
                :key="question.order"
                :parent="answer"
                :question="question"
                :bg="current_bg"
              ></Question>
              <div class="form-group form-group-last row mt-3">
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click.prevent="
                      addQuestion(
                        answer,
                        answer.questions.length
                          ? answer.questions.length + 1
                          : 1
                      )
                    "
                  >
                    <i class="la la-plus"></i> Добавить вопрос
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-group row"
        v-if="question.type === 4 || question.type === 2"
      >
        <div class="col-12">
          <a
            href="#"
            class="btn btn-bold btn-sm btn-success"
            @click.prevent="
              addAnswers(
                question,
                question.answers.length ? question.answers.length + 1 : 1
              )
            "
            ><i class="la la-plus"></i> Добавить ответ</a
          >
        </div>
      </div>
    </div>

    <!-- type = 2 / 4-->
    <div
      class="container-fluid px-0"
      v-if="question.type === 2 || question.type === 4"
    >
      <div class="form-group row">
        <label class="col-form-label col-3">Текст вопроса</label>
        <div class="col-9">
          <input
            type="text"
            class="form-control"
            v-model="question.text"
            placeholder="Текст вопроса"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row mb-3"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>

      <div
        class="form-group row"
        v-for="answer in question.answers"
        :key="answer.order"
      >
        <label class="col-form-label col-2">Текст ответа</label>
        <div class="col-5">
          <input
            type="text"
            class="form-control"
            v-model="answer.text"
            :readonly="answer.readonly"
          />
        </div>
        <div class="col-3 d-flex align-items-center">
          <b-form-group>
            <b-form-checkbox
              v-model="answer.isFile"
              switch
              stacked
              @change="switchAnswerFile(answer)"
              >Файл</b-form-checkbox
            >
          </b-form-group>
        </div>
        <div class="col-2" v-if="answer.subs || answer.deletable">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="custom-v-dropdown"
            no-caret
            right
            no-flip
            text="Действия"
            v-b-tooltip.hover="'Действия'"
          >
            <template v-slot:button-content>
              <a href="#" class="btn btn-icon" data-toggle="dropdown">
                <span class="svg-icon svg-icon-success svg-icon-2x">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Code/Plus.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </template>
            <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                <i
                  class="flaticon2-information"
                  data-toggle="tooltip"
                  data-placement="left"
                  v-b-tooltip.hover
                  title="Добавление вложенного вопроса позволяет добавить следующий уровень вопросов, например, при определенном ответе, вы можете задать уточняющие вопросы. Удаление убирает вопрос со всеми подвопросами из структуры теста"
                />
                Для этого ответа доступны действия:
              </b-dropdown-text>
              <b-dropdown-text
                tag="div"
                class="navi-separator mb-3"
              ></b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item" v-if="answer.subs">
                <a
                  href="#"
                  class="navi-link"
                  @click.prevent="
                    addQuestion(
                      answer,
                      answer.questions.length ? answer.questions.length + 1 : 1
                    )
                  "
                >
                  <span class="navi-icon">
                    <i class="flaticon-add-circular-button"></i>
                  </span>
                  <span class="navi-text">Добавить уточняющий вопрос</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item">
                <a
                  href="#"
                  class="navi-link"
                  @click.prevent="
                    copyPasteAnswer(answer, question.answers.length + 1)
                  "
                >
                  <span class="navi-icon">
                    <i class="flaticon-add-circular-button"></i>
                  </span>
                  <span class="navi-text">Дублировать</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-text
                tag="div"
                class="navi-item"
                v-if="answer.deletable && question.answers.length > 1"
              >
                <a
                  href="#"
                  class="navi-link"
                  @click.prevent="deleteAnswer(answer)"
                >
                  <span class="navi-icon">
                    <i class="flaticon-delete-1"></i>
                  </span>
                  <span class="navi-text">Удалить</span>
                </a>
              </b-dropdown-text>
            </div>
          </b-dropdown>
        </div>

        <template v-if="answer.isFile">
          <div
            class="form-group col-12 mt-3 mb-3"
            v-if="answer.file === '' || answer.file === null"
          >
            <input
              type="file"
              class="form-control"
              placeholder="Выбрать файл PDF/Изображение/Видео"
              accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
              @change="onAnswerFileChange(answer, $event)"
            />
          </div>
          <div class="form-group row" v-else>
            <div v-html="answer.currentFile" class="w-100 mt-3 mb-3"></div>
            <p>
              Файл загружен
              <a href="#" @click.prevent="removeFile(answer)">Удалить</a>
            </p>
          </div>
        </template>

        <div class="container-fluid px-0" v-if="answer.questions.length">
          <div class="card mt-3 pt-3" :class="bgs[current_bg]">
            <div class="card-body">
              <Question
                v-for="question in answer.questions"
                :key="question.order"
                :parent="answer"
                :question="question"
                :bg="current_bg"
              ></Question>
              <div class="form-group form-group-last row mt-3">
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click.prevent="
                      addQuestion(
                        answer,
                        answer.questions.length
                          ? answer.questions.length + 1
                          : 1
                      )
                    "
                  >
                    <i class="la la-plus"></i> Добавить вопрос
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form-group row"
        v-if="question.type === 4 || question.type === 2"
      >
        <div class="col-12">
          <a
            href="#"
            class="btn btn-bold btn-sm btn-success"
            @click.prevent="
              addAnswers(
                question,
                question.answers.length ? question.answers.length + 1 : 1
              )
            "
            ><i class="la la-plus"></i> Добавить ответ</a
          >
        </div>
      </div>
    </div>

    <!-- type = 3 -->
    <div
      class="container-fluid px-0"
      v-if="question.type === 3 || question.type === 13"
    >
      <div class="form-group row">
        <label class="col-form-label col-3">Текст для поля</label>
        <div class="col-9">
          <input
            type="text"
            class="question-text form-control"
            v-model="question.text"
            placeholder="Текст для поля"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>
    </div>

    <!-- type = 5 -->
    <div class="container-fluid px-0" v-if="question.type === 5">
      <div class="form-group row">
        <label class="col-form-label col-3"
          >Сопровождающий или поясняющий текст</label
        >
        <div class="col-9">
          <input
            type="text"
            class="question-text form-control"
            v-model="question.text"
            placeholder="Сопровождающий или поясняющий текст"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>
    </div>

    <!-- type = 6 -->
    <div class="container-fluid px-0" v-if="question.type === 6">
      <div class="form-group row">
        <label class="col-form-label col-3"
          >Сопровождающий или поясняющий текст</label
        >
        <div class="col-9">
          <input
            type="text"
            class="question-text form-control"
            v-model="question.text"
            placeholder="В отношении сотрудника"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>
    </div>

    <!-- type = 7 -->
    <div class="container-fluid px-0" v-if="question.type === 7">
      <div class="form-group row">
        <label class="col-form-label col-3">Текст для даты</label>
        <div class="col-9">
          <input
            type="text"
            class="question-text form-control"
            v-model="question.text"
            placeholder="Текст для поля с датой"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row mb-3"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>
    </div>

    <!-- type = 9 -->
    <div class="container-fluid px-0" v-if="question.type === 9">
      <div class="form-group row">
        <label class="col-form-label col-3">Текст пояснения</label>
        <div class="col-9">
          <input
            type="text"
            class="form-control"
            v-model="question.text"
            placeholder="Сопровождающий текст"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>
    </div>

    <!-- type = 11 -->
    <div class="container-fluid px-0" v-if="question.type === 11">
      <div class="form-group row">
        <label class="col-form-label col-3"
          >Сопровождающий или поясняющий текст</label
        >
        <div class="col-9">
          <input
            type="text"
            class="question-text form-control"
            v-model="question.text"
            placeholder="Текст для графического ответа"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>
    </div>

    <!-- type = 12 -->
    <div class="container-fluid px-0" v-if="question.type === 12">
      <div class="form-group row">
        <label class="col-form-label col-3"
          >Сопровождающий или поясняющий текст</label
        >
        <div class="col-9">
          <input
            type="text"
            class="question-text form-control"
            v-model="question.text"
            placeholder="Текст для ответа"
          />
        </div>
      </div>

      <template v-if="isFile">
        <div
          class="form-group row"
          v-if="question.file === '' || question.file === null"
        >
          <input
            type="file"
            class="form-control"
            placeholder="Выбрать файл PDF/Изображение/Видео"
            accept="application/pdf,image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/x-m4v,video/*"
            @change="onFileChange(question, $event)"
          />
        </div>
        <div class="form-group row" v-else>
          <div v-html="currentFile" class="w-100 mt-3 mb-3"></div>
          <p>
            Файл загружен
            <a href="#" @click.prevent="removeFile(question)">Удалить</a>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<style>
input.form-control[type="range"] {
  padding-left: 0;
  padding-right: 0;
}
.btn-group {
  display: block;
}
</style>

<script>
//import Answer from "./Answer";

export default {
  name: "Question",

  /*components: {
    Answer,
  },*/

  props: {
    parent: Object,
    question: Object,
    bg: Number
  },

  data: () => ({
    isFile: false,
    bgs: ["bg-light-warning", "bg-light-success", "bg-light-primary"],
    question_types: [
      {
        id: 0,
        template: ""
      },
      {
        id: 1,
        text: "Вариант одного ответа - Да/Нет"
      },
      {
        id: 10,
        text: "Вариант одного ответа - Да/Нет/Не знаю"
      },
      {
        id: 2,
        text: "Выбор нескольких ответов"
      },
      {
        id: 3,
        text: "Простой текстовый ответ"
      },
      {
        id: 13,
        text: "Простой числовой ответ"
      },
      {
        id: 4,
        text: "Один ответ из нескольких вариантов"
      },
      {
        id: 5,
        text: "Сделайте или прикрепите фотографию"
      },
      {
        id: 6,
        text: "Ответ с выбором сотрудника(ов)"
      },
      {
        id: 7,
        text: "Ответ с выбором даты"
      },
      {
        id: 8,
        text: "Координаты места проведения"
      },
      {
        id: 9,
        text: "Ознакомление с документом",
        value: ""
      },
      {
        id: 11,
        text: "Графический ответ",
        value: ""
      },
      {
        id: 12,
        text: "Выбор диапазона"
      }
    ],
    currentFile: ""
  }),

  computed: {
    filtered_question_types: {
      get: function() {
        return this.question_types.filter(
          x => x.id !== 0 && x.id !== 8 && x.id !== 9
        );
      }
    },
    current_bg: {
      get: function() {
        return this.bg >= 2 ? 0 : this.bg + 1;
      }
    }
  },

  created() {
    if (
      this.question.hasOwnProperty("file") &&
      this.question.file !== "" &&
      this.question.file !== null
    ) {
      this.isFile = true;
      var ext = this.question.file
        .match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
        .split("/")
        .pop();
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          this.currentFile =
            '<img src="' + this.question.file + '" class="img-fluid" />';
          break;
        case "pdf":
          this.currentFile =
            '<embed  src="' + this.question.file + '" class="w-100" />';
          break;
        case "webm":
        case "mp4":
        case "mpeg":
          this.currentFile =
            '<video controls><source src="' + this.question.file + '"></video>';
          break;
      }
    }
    if (
      this.question.hasOwnProperty("answers") &&
      this.question.answers.length
    ) {
      this.question.answers.forEach(answer => {
        if (answer.isFile) {
          var ext = answer.file
            .match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
            .split("/")
            .pop();
          switch (ext) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
              answer.currentFile =
                '<img src="' + answer.file + '" class="img-fluid" />';
              break;
            case "pdf":
              this.currentFile =
                '<embed  src="' + answer.file + '" class="w-100" />';
              break;
            case "webm":
            case "mp4":
            case "mpeg":
              answer.currentFile =
                '<video controls><source src="' + answer.file + '"></video>';
              break;
          }
        }
      });
    }
  },

  methods: {
    deleteQuestion: function(question) {
      this.parent.questions = this.parent.questions.filter(function(obj) {
        return JSON.stringify(obj) !== JSON.stringify(question);
      });
      var counter = 1;
      this.parent.questions = this.parent.questions.map(question => ({
        ...question,
        order: counter++
      }));
    },

    updateAnswers: function(question) {
      delete question.answers;
      switch (question.type) {
        case 1:
          this.$set(question, "answers", [
            {
              text: "Да",
              placeholder: "Текст ответа",
              subs: true,
              deletable: false,
              questions: [],
              readonly: true,
              order: 1,
              isFile: false,
              file: ""
            },
            {
              text: "Нет",
              placeholder: "Текст ответа",
              subs: true,
              deletable: false,
              questions: [],
              readonly: true,
              order: 2,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 2:
          this.$set(question, "answers", [
            {
              text: "Вариант 1",
              placeholder: "Текст ответа",
              subs: true,
              deletable: true,
              questions: [],
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            },
            {
              text: "Вариант 2",
              placeholder: "Текст ответа",
              subs: true,
              deletable: true,
              questions: [],
              readonly: false,
              order: 2,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 3:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Текст ответа",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 4:
          this.$set(question, "answers", [
            {
              text: "Вариант 1",
              placeholder: "Текст ответа",
              subs: true,
              deletable: true,
              questions: [],
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            },
            {
              text: "Вариант 2",
              placeholder: "Текст ответа",
              subs: true,
              deletable: true,
              questions: [],
              readonly: false,
              order: 2,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 5:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Выберите или сделайте фото",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 6:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Укажите сотрудников",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 7:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Выберите дату",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 8:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Укажите место проведения",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 9:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Введите ваш ответ",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 10:
          this.$set(question, "answers", [
            {
              text: "Да",
              placeholder: "Текст ответа",
              subs: true,
              deletable: false,
              questions: [],
              readonly: true,
              order: 1,
              isFile: false,
              file: ""
            },
            {
              text: "Не знаю",
              placeholder: "Текст ответа",
              subs: true,
              deletable: false,
              questions: [],
              readonly: true,
              order: 2,
              isFile: false,
              file: ""
            },
            {
              text: "Нет",
              placeholder: "Текст ответа",
              subs: true,
              deletable: false,
              questions: [],
              readonly: true,
              order: 3,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 11:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Введите ваш ответ",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        case 12:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Введите ваш ответ",
              subs: false,
              deletable: false,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
        default:
          this.$set(question, "answers", [
            {
              text: " ",
              placeholder: "Текст ответа",
              subs: false,
              deletable: true,
              readonly: false,
              order: 1,
              isFile: false,
              file: ""
            }
          ]);
          break;
      }
    },

    addAnswers: function(question, order) {
      question.answers.push({
        text: "",
        placeholder: "Текст ответа",
        subs: true,
        deletable: true,
        questions: [],
        readonle: false,
        order: order,
        isFile: false,
        file: ""
      });
    },

    deleteAnswer(answer) {
      this.question.answers = this.question.answers.filter(function(obj) {
        return JSON.stringify(obj) !== JSON.stringify(answer);
      });
      var counter = 1;
      this.question.answers = this.question.answers.map(answer => ({
        ...answer,
        order: counter++
      }));
    },

    addQuestion(answer, order) {
      answer.questions.push({
        type: 0,
        text: "",
        value: "",
        file: "",
        answers: [
          {
            text: "",
            placeholder: "Текст ответа",
            subs: true,
            deletable: true,
            questions: [],
            readonly: false,
            order: 1
          }
        ],
        order: order,
        required: true
      });
    },

    copyPasteQuestion: function(question, order) {
      this.parent.questions.push({
        type: question.type,
        text: question.text,
        value: question.value,
        file: question.file,
        answers: question.answers,
        order: order,
        required: question.required
      });
    },

    copyPasteAnswer(answer, order) {
      this.question.answers.push({
        text: answer.text,
        placeholder: answer.placeholder ? answer.placeholder : "",
        subs: answer.subs,
        deletable: answer.deletable,
        questions: answer.questions,
        readonle: answer.readonle,
        order: order
      });
    },

    switchFile() {
      if (!this.isFile) this.removeFile(this.question);
    },

    switchAnswerFile(answer) {
      if (!answer.isFile) answer.file === "";
    },

    onFileChange(question, e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createFile(question, files[0]);
    },

    createFile(question, file) {
      var vm = this;
      var reader = new FileReader();
      reader.onload = e => {
        question.file = e.target.result;
        var ext = question.file
          .match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          .split("/")
          .pop();
        switch (ext) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            vm.currentFile =
              '<img src="' + question.file + '" class="img-fluid" />';
            break;
          case "pdf":
            vm.currentFile =
              '<embed  src="' + question.file + '" class="w-100" />';
            break;
          case "webm":
          case "mp4":
          case "mpeg":
            vm.currentFile =
              '<video controls><source src="' + question.file + '"></video>';
            break;
        }
      };
      reader.readAsDataURL(file);
    },

    onAnswerFileChange(answer, e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createAnswerFile(answer, files[0]);
    },

    createAnswerFile(answer, file) {
      var reader = new FileReader();
      reader.onload = e => {
        answer.file = e.target.result;
        var ext = answer.file
          .match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          .split("/")
          .pop();
        switch (ext) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            answer.currentFile =
              '<img src="' + answer.file + '" class="img-fluid" />';
            break;
          case "pdf":
            answer.currentFile =
              '<embed  src="' + answer.file + '" class="w-100" />';
            break;
          case "webm":
          case "mp4":
          case "mpeg":
            answer.currentFile =
              '<video controls><source src="' + answer.file + '"></video>';
            break;
        }
      };
      reader.readAsDataURL(file);
    },

    removeFile: function(question) {
      question.file = "";
    }
  }

  /* beforeDestroy() {
    if (
      this.question.hasOwnProperty("answers") &&
      this.question.answers.length
    ) {
      this.question.answers.forEach(answer => {
        delete answer.currentFile;
      });
    }
  } */
};
</script>
